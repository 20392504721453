import React from "react";
import styled from "styled-components";

import mediaQueries from "../mediaQueries";

import { ReactComponent as MailIcon } from "../img/mailIcon.svg";
import { ReactComponent as PhoneIcon } from "../img/phoneIcon.svg";
import { ReactComponent as TwitterIcon } from "../img/twitterIcon.svg";

const ContactComponent = styled.div`
  background-color: #fff;
  /* height: 30rem; */
  border-radius: 3px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media ${mediaQueries.md} {
    flex-direction: row;
  }
`;

const ContactInfo = styled.div`
  /* flex-grow: 1; */
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3rem 0;

  @media ${mediaQueries.md} {
    width: 50%;
    padding: 0;
  }

  div.listItem {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  a {
    transition: all 0.2s;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: #7cc84b;
    }
  }

  p,
  a {
    color: #fff;
    font-size: 1rem;
    margin-left: 1rem;

    @media ${mediaQueries.md} {
      font-size: 1.2rem;
    }
  }
`;

const ContactText = styled.div`
  /* flex-grow: 1; */
  width: 100%;
  padding: 3rem;
  text-align: center;

  @media ${mediaQueries.md} {
    width: 50%;
    text-align: left;
  }
`;

const ContactTitle = styled.h3`
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
`;

const ContactParagraph = styled.p`
  line-height: 1.8;
  font-size: 1rem;

  @media ${mediaQueries.md} {
    font-size: 1.2rem;
  }
`;

export default function Contact() {
  return (
    <ContactComponent>
      <ContactText>
        <ContactTitle>Get in touch!</ContactTitle>
        <ContactParagraph>
          We love to hear from our players. If you have any questions about our
          games, our plans for the future or if you want to give feedback, don’t
          hesitate to get in touch with us. Just send us an email or reach out
          to us via our social media pages.
        </ContactParagraph>
      </ContactText>
      <ContactInfo>
        <div>
          <div className="listItem">
            <MailIcon></MailIcon>
            <a href="mailto:info@eagleowlstudios.com">
              info@eagleowlstudios.com
            </a>
          </div>
          <div className="listItem">
            <PhoneIcon></PhoneIcon>
            <p>+31 6 24 98 18 04</p>
          </div>
          <div className="listItem">
            <TwitterIcon></TwitterIcon>
            <a href="https://twitter.com/EagleOwlStudio1">@EagleOwlStudio1</a>
          </div>
        </div>
      </ContactInfo>
    </ContactComponent>
  );
}
