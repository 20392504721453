import React from "react";
import styled from "styled-components";

import mediaQueries from "../mediaQueries";

const AboutStyles = styled.div`
  background-color: #7cc84b;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  height: auto;
  align-items: stretch;

  @media ${mediaQueries.md} {
    flex-direction: row-reverse;
  }

  div {
    &:nth-child(1) {
      height: 100%;
      background-color: #fff;
      /* flex-grow: 2; */
      width: 100%;
      padding: 3rem;
      line-height: 1.8;

      @media ${mediaQueries.md} {
        width: 66%;
      }

      p {
        font-size: 1rem;
        text-align: center;

        @media ${mediaQueries.md} {
          text-align: left;
          font-size: 1.2rem;
        }

        &:not(:first-child) {
          border-top: 1px solid #7cc84b;
          margin-top: 1.5rem;
          padding-top: 1.5rem;
        }
      }
    }

    &:nth-child(2) {
      flex-basis: 1;
      background-image: url("https://images.unsplash.com/photo-1531973576160-7125cd663d86?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80");
      background-size: cover;
      background-position: center;
      flex-grow: 1;
      /* margin: 3rem 0 3rem 3rem; */
    }
  }
`;

export default function About() {
  return (
    <AboutStyles>
      <ContentContainer>
        <div>
          <p>
            We are a small Dutch indie game development studio, founded in 2019,
            that believes in the potential of mobile esports. Therefore we
            partnered with EazeGames and GameTransactionz.
          </p>
          <p>
            In today's hectic world players don't take the time to play games
            for more than 10 minutes. They want to play simple games that need
            skills and concentration and that can be played throughout the day.
            We think that is why hyper casual gaming is such a successful genre.
            It is even better if players can compete against other players to
            see who is best and win some money along the way.
          </p>
          <p>
            Our goal is to make games that are not only engaging and
            challenging, but also enable players to interact with each other and
            even make new friends.
          </p>
        </div>
        <div></div>
      </ContentContainer>
    </AboutStyles>
  );
}
