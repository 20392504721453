import React, { useState, useEffect } from "react";
import Div100vh from "react-div-100vh";
import styled, { keyframes } from "styled-components";
import Social from "./Social";

import mediaQueries from "../mediaQueries";

import { ReactComponent as Logo } from "../img/Logo_green.svg";

const slideDown = keyframes`
  from {
    transform: translateY(-120%);
    opacity: 0;
  }
  
  to {
    transform: translateY(0);
    opacity: 1; 
  }
`;

const slideRight = keyframes`
  from {
    transform: translateX(-120%);
    opacity: 0;
  }
  
  to {
    transform: translateX(0);
    opacity: 1; 
  }
`;

const bounce = keyframes`
  from {
    transform: translateY(3px);
  };
  to {
    transform: translateY(-3px);
  };
`;

const Header = styled.header`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff;

  @media ${mediaQueries.lg} {
    flex-direction: row;
  }

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: #7cc84b;
    /* z-index: -1; */
    clip-path: polygon(0% 90%, 100% 85%, 100% 100%, 0% 100%);
  }
`;

const LogoContainer = styled.div`
  width: 10rem;
  margin-bottom: 2rem;

  @media ${mediaQueries.lg} {
    margin-bottom: 0;
    margin-right: 2rem;
  }
  @media ${mediaQueries.xl} {
    width: 12rem;
  }
  @media ${mediaQueries.xxl} {
    width: 15rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 3px solid #000;
  padding: 1rem 0rem;
  overflow: hidden;
  max-width: 16rem;

  @media ${mediaQueries.md} {
    max-width: none;
  }
  @media ${mediaQueries.lg} {
    padding: 0 2rem;
    border-top: none;
    border-left: 3px solid #000;
  }
`;

const AnimationContainer = styled.div`
  animation: ${slideDown} 1.5s ease-in-out;

  @media ${mediaQueries.lg} {
    animation: ${slideRight} 1.5s ease-in-out;
  }
`;

const Title = styled.h1`
  display: inline-block;
  font-size: 3rem;
  font-weight: 900;
  margin-bottom: 1rem;

  color: #000;
  text-align: center;

  @media ${mediaQueries.lg} {
    font-size: 4rem;
    text-align: left;
  }
  @media ${mediaQueries.xl} {
    font-size: 5rem;
    margin-bottom: 1.5rem;
  }
  @media ${mediaQueries.xxl} {
    font-size: 6rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 100;
  margin-bottom: 0.5rem;

  color: #000;
  text-align: center;

  @media ${mediaQueries.lg} {
    font-size: 2rem;
    text-align: left;
  }
  @media ${mediaQueries.xl} {
    font-size: 2.5rem;
  }
  @media ${mediaQueries.xxl} {
    font-size: 3rem;
  }
`;

const ScrollDown = styled.p`
  position: absolute;
  bottom: 2rem;
  animation: ${bounce} 1s ease-in-out infinite alternate;
  color: #fff;
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  transition: opacity 0.3s;

  @media ${mediaQueries.xl} {
    bottom: 3rem;
  }
`;

const SocialHeader = styled(Social)`
  position: absolute;
  z-index: 2;
  top: 2rem;

  @media ${mediaQueries.lg} {
    top: unset;
    right: 2rem;
  }
  @media ${mediaQueries.xl} {
    top: unset;
    right: 3rem;
  }
`;

const Emphasis = styled.span`
  color: #7cc84b;
`;

export default function Heading() {
  const [isVisible, setIsVisible] = useState(true);

  const mount = () => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    });
  };

  useEffect(mount, []);

  return (
    <Div100vh>
      <Header>
        <LogoContainer>
          <Logo></Logo>
        </LogoContainer>
        <HeaderContainer>
          <AnimationContainer>
            <Title>
              Eagle Owl <Emphasis>Studios</Emphasis>
            </Title>
            <Subtitle>Making mobile games</Subtitle>
          </AnimationContainer>
        </HeaderContainer>
        <ScrollDown isVisible={isVisible}>scroll down</ScrollDown>
        <SocialHeader></SocialHeader>
      </Header>
    </Div100vh>
  );
}
