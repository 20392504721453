import React from "react";
import Heading from "../components/Heading";
import Wrapper from "../components/Wrapper";
import styled from "styled-components";
import AppGallery from "../components/AppGallery";
import About from "../components/About";
import Footer from "../components/Footer";
import Contact from "../components/Contact";

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const TitleWhite = styled(Title)`
  color: #fff;
`;

const AboutBackground = styled.div`
  background-color: #7cc84b;
  /* background-color: #fff; */
`;

const ContactBackground = styled.div`
  /* background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
    url("https://images.unsplash.com/photo-1534803522048-835d05301b08?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1309&q=80"); */
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
    url("https://images.unsplash.com/photo-1499159058454-75067059248a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export default function App() {
  return (
    <div>
      <Heading></Heading>
      <Wrapper>
        <Title>Our Games</Title>
        <AppGallery></AppGallery>
      </Wrapper>
      <AboutBackground>
        <Wrapper>
          <TitleWhite>About Us</TitleWhite>
          <About></About>
        </Wrapper>
      </AboutBackground>
      <ContactBackground>
        <Wrapper>
          <Title>Contact Us</Title>
          <Contact></Contact>
        </Wrapper>
      </ContactBackground>
      <Footer></Footer>
    </div>
  );
}
