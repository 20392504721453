import React from "react";
import styled from "styled-components";

import ColorCashIcon from "../img/appicon1.png";
import BubbleBricksIcon from "../img/appicon2.png";

import mediaQueries from "../mediaQueries";
import Card from "./Card";

const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media ${mediaQueries.sm} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${mediaQueries.lg} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const ComingSoonCard = styled.div`
  border-bottom: solid 5px #7cc84b;
  overflow: hidden;
  background-color: white;
  height: 100%;
  padding: 5rem;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  text-align: center;
`;

export default function AppGallery() {
  return (
    <Gallery>
      <Card
        name="Bubble Bricks"
        icon={BubbleBricksIcon}
        link="https://apps.apple.com/nl/app/bubble-bricks-win-real-money/id1495058830"
      >
        Compete for cash prizes in this puzzle block game!
      </Card>
      <Card
        name="Color Cash"
        icon={ColorCashIcon}
        link="https://apps.apple.com/nl/app/color-cash-win-real-money/id1470057981"
        weblink='http://colorcashgame.com/'
      >
        Tap your way to victory and win real money!
      </Card>
      <ComingSoonCard>More Coming Soon...</ComingSoonCard>
    </Gallery>
  );
}
