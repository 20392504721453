import React from "react";
import styled from "styled-components";
import Tilt from "react-tilt";

import mediaQueries from "../mediaQueries";
import Download from "../img/DownloadButton.svg";

const AppIcon = styled.img`
  width: 100%;
`;

const AppIconTilt = styled(Tilt)`
  width: 100%;
  height: auto;
  border-radius: 18%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppIconContainer = styled.div`
  width: 100%;
  padding: 2.5rem;
  overflow: hidden;
`;

const CardComponent = styled.div`
  border-bottom: solid 5px #7cc84b;
  overflow: hidden;
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  @media ${mediaQueries.md} {
    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 2rem;
    }
  }
`;

const CardContent = styled.div`
  padding: 0 2rem 2rem 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const IconTitle = styled.h3`
  font-size: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: solid 1px #999;
  font-weight: 400;
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: #555;
  line-height: 1.5;
`;

const DownloadButton = styled.img`
  height: 50px;
  margin: 2rem 0 0.5rem 0;
`;

const DownloadContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  @media ${mediaQueries.sm} {
    margin-top: auto;
  }
`;

const WebsiteLink = styled.a`
  text-align: center;
  color: #7cc84b;
  margin-top: 1.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s;

  &:hover {
    color: #000;
  }
`;

const WebsiteAnnouncement = styled.p`
  text-align: center;
  color: #7cc84b;
  margin-top: 1.5rem;
  font-size: 1.2rem;
`;

export default function Card({
  icon,
  link,
  name = "More coming soon",
  children,
  weblink = "",
}) {
  const options = {
    max: 25,
    perspective: 1000,
    scale: 1.1,
  };

  return (
    <CardComponent>
      <AppIconContainer>
        <AppIconTilt options={options}>
          <AppIcon src={icon}></AppIcon>
        </AppIconTilt>
      </AppIconContainer>
      <CardContent>
        <IconTitle>{name}</IconTitle>
        <Description>{children}</Description>
        <DownloadContainer>
          <a href={link}>
            <DownloadButton src={Download}></DownloadButton>
          </a>
        </DownloadContainer>
        {weblink.length > 0 ? (
          <WebsiteLink href={weblink}>Learn more &#8594;</WebsiteLink>
        ) : (
          <WebsiteAnnouncement>Learn more soon</WebsiteAnnouncement>
        )}
      </CardContent>
    </CardComponent>
  );
}
