import React from "react";
import styled from "styled-components";
import Wrapper from "./Wrapper";
import Copyright from "./Copyright";

import { ReactComponent as FacebookIcon } from "../img/social_icons/facebook-white.svg";
import { ReactComponent as TwitterIcon } from "../img/social_icons/twitter-white.svg";
import { ReactComponent as InstagramIcon } from "../img/social_icons/instagram-white.svg";

import GameTranzactionz from "../img/gametransactionz.png";
import EazeGames from "../img/eazegames.png";

import mediaQueries from "../mediaQueries";

const FooterBackground = styled.footer`
  background-color: #222;
  color: #fff;
`;

const FooterStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${mediaQueries.md} {
    flex-direction: row;
  }
`;

const SocialIcon = styled.div`
  height: 2rem;
  width: 2rem;

  .icon {
    min-width: 100%;
    min-height: 100%;
  }
  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

const SocialStyles = styled.div`
  display: flex;
`;

const CompanyStyles = styled.div`
  display: flex;
  margin-top: 2rem;

  @media ${mediaQueries.md} {
    margin-top: 0;
    margin-left: auto;
  }

  img {
    height: 2rem;
  }
  
  a:not(:last-child) {
    margin-right: 1rem;
  }
`;

export default function Footer() {
  return (
    <FooterBackground>
      <Wrapper>
        <FooterStyles>
          <SocialStyles>
            <SocialIcon>
              <a href="https://www.facebook.com/EagleOwlStudios/">
                <FacebookIcon className="icon"></FacebookIcon>
              </a>
            </SocialIcon>
            <SocialIcon>
              <a href="https://twitter.com/EagleOwlStudio1">
                <TwitterIcon className="icon"></TwitterIcon>
              </a>
            </SocialIcon>
            <SocialIcon>
              <a href="https://www.instagram.com/eagleowlstudios/">
                <InstagramIcon className="icon"></InstagramIcon>
              </a>
            </SocialIcon>
          </SocialStyles>
          <CompanyStyles>
            <a href="https://www.gametransactionz.com/">
              <img src={GameTranzactionz} alt="GameTranzactionz" />
            </a>
            <a href="https://eazegames.com/">
              <img src={EazeGames} alt="EazeGames" />
            </a>
          </CompanyStyles>
        </FooterStyles>
      </Wrapper>
      <Copyright></Copyright>
    </FooterBackground>
  );
}
