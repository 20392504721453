import React from "react";
import styled from "styled-components";

const CopyrightStyles = styled.div`
  font-size: 0.8rem;
  padding: 1rem 0;
  background-color: black;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #ccc;
`;

export default function Copyright() {
  return (
    <CopyrightStyles>&copy; Copyright Eagle Owl Studios 2019</CopyrightStyles>
  );
}
