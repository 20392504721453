import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

    * {
        box-sizing: border-box;
    }

    body {
        font-family: 'Manrope', sans-serif;
        font-size: 100%;
    }
`;
