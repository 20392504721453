import React from "react";
import styled from "styled-components";

import mediaQueries from "../mediaQueries";

const WrapperStyles = styled.div`
  padding: 3rem 2rem;
  margin: 0 auto;

  @media ${mediaQueries.md} {
    width: 750px;
  }
  @media ${mediaQueries.lg} {
    width: 970px;
  }
  @media ${mediaQueries.xl} {
    width: 1170px;
  }
`;

export default function Wrapper({ children }) {
  return <WrapperStyles>{children}</WrapperStyles>;
}
