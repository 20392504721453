import React from "react";
import styled from "styled-components";

import mediaQueries from "../mediaQueries";

import { ReactComponent as FacebookIcon } from "../img/social_icons/iconmonstr-facebook-3.svg";
import { ReactComponent as TwitterIcon } from "../img/social_icons/iconmonstr-twitter-3.svg";
import { ReactComponent as InstagramIcon } from "../img/social_icons/iconmonstr-instagram-13.svg";

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${mediaQueries.lg} {
    flex-direction: column;
  }
`;

const SocialIcon = styled.div`
  height: 2rem;
  width: 2rem;
  &:not(:last-child) {
    margin-right: 1.5rem;
  }

  .icon {
    min-height: 100%;
    min-width: 100%;
  }

  fill: #000;

  @media ${mediaQueries.lg} {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
  }
`;

export default function Social({ className }) {
  return (
    <SocialContainer className={className}>
      <SocialIcon>
        <a href="https://www.facebook.com/EagleOwlStudios/">
          <FacebookIcon className="icon"></FacebookIcon>
        </a>
      </SocialIcon>
      <SocialIcon>
        <a href="https://twitter.com/EagleOwlStudio1">
          <TwitterIcon className="icon"></TwitterIcon>
        </a>
      </SocialIcon>
      <SocialIcon>
        <a href="https://www.instagram.com/eagleowlstudios/">
          <InstagramIcon className="icon"></InstagramIcon>
        </a>
      </SocialIcon>
    </SocialContainer>
  );
}
